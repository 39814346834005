<template>
  <div class="basic">
    <div class="explain-inner">
      <div class="explain">说明：设置限制次数n；</div>
      <div class="explain">用户的月退款次数≤n时，24小时系统主动退款；</div>
      <div class="explain">用户的月退款次数＞n时，系统判定为行为异常用户，进入审核列表，异常退款设定扣除百分比。</div>
    </div>
    <el-card class="basic-card">
      <el-form ref="form" class="vendorFrom" label-position="right" :rules="rules" :model="form" label-width="210px">
        <el-form-item label="退款设置">
          <el-switch :inactive-value="0" :active-value="1" v-model="form.refundState"> </el-switch>
          <span class="assist"></span>
        </el-form-item>
        <el-form-item label="退款次数" prop="refundNumLimit" v-if="form.refundState === 1">
          <el-input class="temp" oninput="value=value.replace(/[^0-9]/g,'')" v-model="form.refundNumLimit">
            <template slot="append">/月</template></el-input
          >
        </el-form-item>
        <el-form-item label="正常退款比例" prop="chargeProportion" v-if="form.refundState === 1">
          <el-input class="temp" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.chargeProportion">
            <template slot="append">%</template></el-input
          >
          <bm-tip>用于未核销的核销码，用户申请退款时的扣除比例</bm-tip>
        </el-form-item>
        <el-form-item label="过期扣除比例" prop="expiredProportion" v-if="form.refundState === 1">
          <el-input class="temp" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.expiredProportion">
            <template slot="append">%</template></el-input
          >
          <bm-tip>用于过期的核销码，用户申请退款时的扣除比例</bm-tip>
        </el-form-item>
        <el-form-item label="异常用户扣除比例" prop="exceptionProportion" v-if="form.refundState === 1">
          <el-input class="temp" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.exceptionProportion">
            <template slot="append">%</template></el-input
          >
        </el-form-item>
        <el-form-item v-if="form.refundState === 1">
          <span class="assist">申请退款-原路退回方式，将扣除此比例费用</span>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="settingMall">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        id: 0,
        refundState: 1,
        refundNumLimit: 0,
        chargeProportion: 0,
        expiredProportion: 0,
        exceptionProportion: 0,
        refundNumLimit: 5
      },
      activeName: 'basic',
      rules: {}
    }
  },
  components: {},
  methods: {
    getRules() {
      this.rules = this.$initRules([
        {
          label: '扣除用户数',
          value: 'chargeProportion',
          type: 'input',
          required: true
        }
      ])
    },
    // 修改商城设置的信息
    settingMall() {
      if (this.form.refundState === 0) {
        this.form.chargeProportion = 0
      }
      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        } else {
          let params = { ...this.form }
          if (this.form.id === 0) {
            const loading = this.$loading({
              lock: true,
              text: '正在提交，请稍候',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$http
              .post('boom-center-admin-service/sysAdmin/mallSetting', params)
              .then(res => {
                if (res.code === 5000) {
                  this.$message.warning(res.msg)
                } else {
                  this.$message.success('添加成功')
                  this.getMallsSetting()
                }
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                loading.close()
              })
          } else {
            const loading = this.$loading({
              lock: true,
              text: '正在提交，请稍候',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })

            this.$http
              .put('boom-center-admin-service/sysAdmin/mallSetting', params)
              .then(res => {
                if (res.code === 5000) {
                  this.$message.warning(res.msg)
                } else {
                  this.$message.success('修改成功')
                  this.getMallsSetting()
                }
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                loading.close()
              })
          }
        }
      })
    },
    // 获取原先商城设置的信息
    getMallsSetting() {
      this.$http
        .get('boom-center-admin-service/sysAdmin/mallSetting')
        .then(res => {
          if (res) {
            this.form = res
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  },
  created() {
    this.getRules()
    this.getMallsSetting()
  }
}
</script>

<style lang="less" scoped>
.explain-inner {
  padding: 0 20px 20px 20px;
  line-height: 26px;
}
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
  .vendorFrom {
    margin-left: 20px;
    .el-input-number,
    .el-select,
    .el-input {
      width: 250px;
    }

    .assist {
      .assistText;
    }
  }
}
</style>
