<template>
  <div>
    <el-card class="classify">
      <div slot="header" class="clearfix">
        <span>退款分类列表</span>
        <el-button @click="addRefund()" type="success" style="float: right; ">添加</el-button>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="sortNo" label="分类排序"> </el-table-column>
        <el-table-column align="center" prop="name" label="分类名称"> </el-table-column>
        <el-table-column align="center" prop="icon" label="分类图标">
          <template slot-scope="scope">
            <!-- <img :src="scope.row.icon" alt="" width="50" height="50" /> -->

            <div class="image" v-bg="scope.row.icon"></div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="是否启用">
          <template slot-scope="scope">
            <span>
              {{ scope.row.enable === 0 ? '不启用' : '启用' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="售后">
          <template slot-scope="scope">
            <span>
              {{ scope.row.afterSalesService === 0 ? '不启用' : '启用' }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope)" type="text" size="small">编辑 </el-button>
            <el-button @click="showDelete(scope)" type="text" size="small" class="deleteBtnText">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>

      <!-- 新增 && 编辑标签 -->
      <el-dialog
        :close-on-click-modal="false"
        class="refundDialog"
        :title="refundDialogTitle"
        :visible.sync="refundDialog"
        width="20%"
      >
        <el-form ref="form" :rules="rules" :model="form" label-width="120px">
          <el-form-item label="分类名称" prop="name">
            <el-input v-model.trim="form.name"></el-input>
          </el-form-item>
          <el-form-item label="分类图标" prop="icon">
            <bm-upload v-model="form.icon" type="system"></bm-upload>
          </el-form-item>
          <el-form-item label="排序优先级" prop="sortNo">
            <el-input-number
              v-model="form.sortNo"
              controls-position="right"
              :min="1"
              :step="1"
              :max="999"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="是否启用">
            <el-switch :active-value="1" :inactive-value="0" v-model="form.enable"></el-switch>
          </el-form-item>
          <el-form-item label="是否售后">
            <el-switch :active-value="1" :inactive-value="0" v-model="form.afterSalesService"></el-switch>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button round @click="refundDialog = false">取消</el-button>
          <el-button round type="primary" @click="editRefundType">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 删除的组件 -->
      <bm-deleteItem
        url="boom-center-admin-service/sysAdmin/refundReasonType/"
        :timestamp="timestamp"
        @successDelete="successDelete"
      ></bm-deleteItem>
    </el-card>
  </div>
</template>

<script>
// 引入分页
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'
export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      refundDialogTitle: '',
      refundDialog: false,
      searchValue: {},
      form: { name: '', icon: '', sortNo: 1, enable: 1, afterSalesService: 0 },
      list: [],
      addOrEdit: 0,
      timestamp: 0,
      rules: []
    }
  },
  created() {
    this.rules = this.$initRules([
      {
        label: '分类名称',
        value: 'name',
        type: 'input',
        required: true
      },
      {
        label: '分类图标',
        value: 'icon',
        type: 'input',
        required: true
      },
      {
        label: '排序',
        value: 'sortNo',
        type: 'input',
        required: true
      }
    ])
  },
  methods: {
    getRules() {},

    // 点击新增标签
    addRefund() {
      this.form = { name: '', icon: '', sortNo: 1, enable: 1, afterSalesService: 0 }
      this.addOrEdit = 0
      this.refundDialogTitle = '新增退款分类'
      this.refundDialog = true
    },
    // 编辑标签
    edit(scope) {
      this.addOrEdit = 1
      this.refundDialog = true
      this.refundDialogTitle = '编辑退款分类'
      this.form = { ...scope.row }
    },
    // 删除成功
    successDelete() {
      this.getList(true)
    },
    // 请求后端获取退款分类列表
    getList(val) {
      this.loading = true
      this.searchValue.page = val === true ? this.currentPage : 1

      this.$http
        .get('boom-center-admin-service/sysAdmin/refundReasonType/page', {
          params: {
            page: this.searchValue.page,
            size: this.pageSize
          }
        })
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    //向后端发起添加或者修改请求
    editRefundType() {
      let params = {
        name: this.form.name,
        icon: this.form.icon,
        sortNo: parseInt(this.form.sortNo),
        enable: parseInt(this.form.enable),
        afterSalesService: parseInt(this.form.afterSalesService)
      }

      let isReturn = false

      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        } else {
          if (this.addOrEdit === 0) {
            this.$http
              .post('boom-center-admin-service/sysAdmin/refundReasonType', params)

              .then(() => {
                this.$message.success('添加成功')
                this.refundDialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg)
              })
          } else {
            params.id = this.form.id
            this.$http
              .put('boom-center-admin-service/sysAdmin/refundReasonType', params)
              .then(() => {
                this.$message.success('修改成功')
                this.refundDialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg)
              })
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.classify {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
}
.refundDialog {
  .el-input-number,
  .el-input {
    width: 200px;
  }
}
.pagination {
  margin-top: 10px;
  text-align: center;
}
.image {
  width: 81px;
  height: 60px;
  flex-shrink: 0;
  margin: auto;
}
</style>
