<template>
  <div>
    <el-card class="editContainer">
      <el-tabs v-model="activeName" @tab-click="handleSetTab">
        <el-tab-pane label="退款基础设置" name="basic">
          <com-basic v-if="activeName === 'basic'"></com-basic>
        </el-tab-pane>
        <el-tab-pane label="退款分类设置" name="classify">
          <com-classify v-if="activeName === 'classify'"></com-classify>
        </el-tab-pane>
        <el-tab-pane label="退款原因设置" name="cause">
          <com-reason v-if="activeName === 'cause'"></com-reason>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import comReason from './__com__/reason.vue'
import comClassify from './__com__/classify.vue'
import comBasic from './__com__/basic.vue'
export default {
  data() {
    return {
      activeName: 'basic'
    }
  },
  components: {
    comReason,
    comClassify,
    comBasic
  },
  methods: {
    handleSetTab(tab) {
      let { name } = tab
      this.activeName = name
    }
  },
  created() {}
}
</script>

<style lang="less" scoped></style>
