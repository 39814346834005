<template>
  <div>
    <el-card class="cause">
      <div slot="header" class="clearfix">
        <span>退款原因列表</span>
        <el-button @click="addRefund()" type="success" style="float: right; ">添加</el-button>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column type="index" label="序号" align="center"> </el-table-column>
        <el-table-column align="center" prop="sortNo" label="优先级"> </el-table-column>
        <el-table-column align="center" prop="typeName" label="退款分类"> </el-table-column>
        <el-table-column align="center" prop="name" label="退款原因"> </el-table-column>

        <el-table-column align="center" label="是否启用">
          <template slot-scope="scope">
            <span>
              {{ scope.row.enable === 0 ? '不启用' : '启用' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope)" type="text" size="small">编辑 </el-button>
            <el-button @click="showDelete(scope)" type="text" size="small" class="deleteBtnText">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>

      <!-- 新增 && 编辑标签 -->
      <el-dialog
        :close-on-click-modal="false"
        class="refundDialog"
        :title="refundDialogTitle"
        :visible.sync="refundDialog"
        width="20%"
      >
        <el-form ref="form" :rules="rules" :model="form" label-width="120px" v-if="refundDialog">
          <el-form-item label="退款分类" prop="typeId">
            <el-select v-model.trim="form.typeId" placeholder="请选择" style="width:200px">
              <el-option v-for="item in reasonTypeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="退款原因" prop="name">
            <el-input v-model.trim="form.name"></el-input>
          </el-form-item>
          <el-form-item label="排序优先级" prop="sortNo">
            <el-input-number
              v-model="form.sortNo"
              controls-position="right"
              :min="1"
              :step="1"
              :max="999"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="是否启用">
            <el-switch :active-value="1" :inactive-value="0" v-model="form.enable"></el-switch>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button round @click="refundDialog = false">取消</el-button>
          <el-button round type="primary" @click="editRefund">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 删除的组件 -->
      <bm-deleteItem
        url="boom-center-admin-service/sysAdmin/refundReason/"
        :timestamp="timestamp"
        @successDelete="successDelete"
      ></bm-deleteItem>
    </el-card>
  </div>
</template>

<script>
// 引入分页
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'
export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      refundDialogTitle: '',
      refundDialog: false,
      searchValue: {},
      form: { typeId: '', name: '', sortNo: 1, enable: 0 },
      list: [],
      addOrEdit: 0,
      timestamp: 0,
      rules: [],
      reasonTypeList: [] // 退款原因分类列表
    }
  },
  created() {
    this.rules = this.$initRules([
      {
        label: '退款分类',
        value: 'typeId',
        type: 'input',
        required: true
      },
      {
        label: '退款名称',
        value: 'name',
        type: 'input',
        required: true
      },
      {
        label: '排序',
        value: 'priority',
        type: 'input',
        required: true
      }
    ])
    this.getReasonTypeList()
  },
  methods: {
    getRules() {},

    // 点击新增标签
    addRefund() {
      this.form = { typeId: '', name: '', sortNo: 1, enable: 0 }
      this.addOrEdit = 0
      this.refundDialogTitle = '新增退款原因'
      this.refundDialog = true
    },
    // 编辑标签
    edit(scope) {
      this.$http
        .get('boom-center-admin-service/sysAdmin/refundReason/' + scope.row.id)
        .then(res => {
          this.addOrEdit = 1
          this.refundDialog = true
          this.refundDialogTitle = '编辑退款原因'
          this.form = { ...res }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 请求后端获取退款原因列表
    getList(val) {
      this.loading = true
      this.searchValue.page = val === true ? this.currentPage : 1
      this.$http
        .get('boom-center-admin-service/sysAdmin/refundReason/page', {
          params: {
            page: this.searchValue.page,
            size: this.pageSize
          }
        })
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    //向后端发起添加或者修改请求
    editRefund() {
      let params = {
        typeId: this.form.typeId,
        name: this.form.name,
        sortNo: this.form.sortNo,
        enable: this.form.enable
      }

      let isReturn = false

      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        } else {
          if (this.addOrEdit === 0) {
            this.$http
              .post('boom-center-admin-service/sysAdmin/refundReason', params)
              .then(() => {
                this.$message.success('添加成功')
                this.refundDialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg)
              })
          } else {
            params.id = this.form.id
            this.$http
              .put('boom-center-admin-service/sysAdmin/refundReason', params)
              .then(() => {
                this.$message.success('修改成功')
                this.refundDialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg)
              })
          }
        }
      })
    },
    // 获取分类列表
    getReasonTypeList() {
      this.$http
        .get('boom-center-admin-service/sysAdmin/refundReasonType/page', {
          params: {
            page: 1,
            size: 10000
          }
        })
        .then(res => {
          this.reasonTypeList = res.list
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped lang="less">
.cause {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
}
.refundDialog {
  .el-input-number,
  .el-input {
    width: 200px;
  }
}
.pagination {
  margin-top: 10px;
  text-align: center;
}
</style>
